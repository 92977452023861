<template>
  <div class="product-box-wide__segment-conversion">
    <span
      v-if="!productBoxData.recurringOrderFollowUpPacket"
      class="product-box-wide__button-wishlist product-box__button-wishlist"
      :class="{
        'product-box__button-wishlist--filled': isWishlistEnabled && productBoxData.inWishlist,
        'product-box__button-wishlist--remove': !isWishlistEnabled,
        'product-box__button-wishlist--loading': state === 'loadingWishlist',
      }"
      @click.prevent="$emit('wishlistClicked')"
    />
    <ratings
      class="product-box__ratings"
      v-if="productBoxData.ratings > minimumRating"
      :rating="productBoxData.ratings"
      :ratings-counter="productBoxData.ratingsCounter"
      :type="settings.rating.type"
    />
    <a
      class="product-box__status product-box__status--basket product-box-wide__status"
      v-if="productBoxData.inBasket"
      :href="basketUrl"
    >
      {{ $t('ads.ads_top_info.article_in_basket') }}
    </a>
    <a
      class="product-box__status product-box__status--basket"
      v-if="productBoxData.successorInBasket && !productBoxData.inBasket && addSuccessorActive"
      :href="basketUrl"
    >
      {{ $t('ads.ads_top_info.successor_in_basket') }}
    </a>
    <div
      class="product-box-wide__product-flags product-box-wide__product-flags--conversion"
      v-if="productBoxData.flags && !productBoxData.inBasket"
    >
      <flags
        :flags="productBoxData.flags"
        :limit="1"
        :white-list="settings.flags.whitelistConversion"
      />
    </div>
    <div class="product-box-wide__price-wrapper">
      <availability-info
        v-if="showAvailability && !productBoxData.recurringOrderFollowUpPacket"
        class="product-box-wide__availability product-box__availability"
        :availability="productBoxData.availability"
      />
      <price-info
        v-if="!hidePrice && productBoxData.price"
        class="product-box-wide__price product-box__price"
        :value="productBoxData.price"
        :alcohol="productBoxData.alcohol"
        :show-star="false"
        :show-tax="true"
      />
      <product-qty-and-cart-btn
        class="product-box-wide__buttons"
        :product-data="productBoxData"
        :product-link="productLink"
        :state="state"
        :add-successor-active="addSuccessorActive"
        @quantityChange="$emit('quantityChange', $event)"
        @addToBasket="$emit('addToBasket', $event)"
        @onRequestClicked="$emit('onRequestClicked', $event)"
      />
    </div>
    <a
      v-if="productBoxData.showFoodSpecificationLink && !hideCounterButton"
      :href="foodSpecificationLink"
      class="product-box__link"
      v-html="$t('detail.product.characteristics')"
    />
  </div>
</template>
<script lang="ts">
  import {Vue, Options, Prop} from 'vue-property-decorator';
  import AvailabilityInfo from '../../../bps/components/availabilityInfo/availabilityInfo.vue';
  import ProductQtyAndCartBtn from '../../../bps/components/productQtyAndCartBtn/productQtyAndCartBtn.vue';
  import Flags from '../flags/flags.vue'
  import type ProductBoxData from '../../../bps/types/ProductBoxData';
  import Lightbox from '../../../bps/components/lightbox/lightbox.vue';
  import Ratings from '../../../bps/components/ratings/ratings.vue';
  import {ProductBoxWideSettings} from './productBoxWideSettings';

  @Options({
    name: 'product-box-wide-segment-conversion',
    components: {
      AvailabilityInfo,
      ProductQtyAndCartBtn,
      Flags,
      Lightbox,
      Ratings
    }
  })
  export default class ProductBoxWideSectionConversion extends Vue {
    @Prop()
    productBoxData: ProductBoxData;
    @Prop()
    settings: ProductBoxWideSettings;
    @Prop()
    minimumRating: string;
    @Prop({default: true})
    isWishlistEnabled: boolean;
    @Prop()
    state: string;
    @Prop()
    foodSpecificationLink: string;
    @Prop()
    showAvailability: boolean;
    @Prop()
    hideCounterButton: boolean;
    @Prop({default: false})
    hidePrice: boolean;
    @Prop()
    basketUrl: String;
    @Prop({default: false})
    addSuccessorActive: boolean;
    @Prop()
    productLink: string;
  }
</script>
