<template>
  <div class="product-box__segment-main">
    <ratings
      class="product-box__ratings"
      v-if="productBoxData.ratings > minimumRating && isBoxTypeVertical"
      :rating="productBoxData.ratings"
      :ratings-counter="productBoxData.ratingsCounter"
      :type="settings.rating.type"
    />
    <div
      v-else
      class="product-box__ratings product-box__ratings--empty"
    />
    <div
      class="product-box__area"
      v-if="isBoxTypeVertical && productBoxData.area && settings.showArea"
    >
      {{ productBoxData.area }}
    </div>
    <a
      class="product-box__status product-box__status--basket"
      v-if="isBoxTypeVertical && productBoxData.inBasket"
      :href="basketUrl"
    >
      {{ $t('ads.ads_top_info.article_in_basket') }}
    </a>
    <a
      class="product-box__status product-box__status--basket"
      v-if="isBoxTypeVertical && productBoxData.successorInBasket && !productBoxData.inBasket && addSuccessorActive"
      :href="basketUrl"
    >
      {{ $t('ads.ads_top_info.successor_in_basket') }}
    </a>
    <div
      class="product-box__tags-wrapper"
      v-if="(productBoxData.flags || productBoxData.tags) && isBoxTypeVertical"
    >
      <flags
        :class="{'product-box__product-flags--hidden': productBoxData.inBasket}"
        :flags="productBoxData.flags"
        :limit="isBoxTypeVertical ? 4 : 3"
        :award-limit="isBoxTypeVertical ? 2 : 1"
        :white-list="settings.flags.whitelistTag"
      />
      <tags
        class="product-box__tags"
        :class="{
          'product-box__tags--more': productBoxData.tags.length > 2,
          'product-box__tags--has-award': productBoxData.flags.awardImageUrls && productBoxData.flags.awardImageUrls.length
        }"
        v-if="isBoxTypeVertical"
        :tags="productBoxData.tags"
        @quantityChange="$emit('quantityChange', $event)"
      />
    </div>
    <div
      v-if="productBoxData.zeroPriceItem"
      class="product-box__price-wrapper product-box__price-wrapper--no-price"
    >
      <div class="product-box__buttons">
        <a
          class="product-box__status product-box__status--unavailable"
          :href="productBoxData.url"
        >
          {{ $t('availability.no-price') }}
        </a>
      </div>
      <a
        v-if="productBoxData.showFoodSpecificationLink && !hideCounterButton"
        :href="foodSpecificationLink"
        class="product-box__link"
        v-html="$t('detail.product.characteristics')"
      />
      <div
        v-else
        class="product-box__link-empty"
      />
    </div>
    <div
      class="product-box__conversion"
      v-else
    >
      <availability-info
        v-if="showAvailability && !productBoxData.recurringOrderFollowUpPacket"
        class="product-box__availability"
        :availability="productBoxData.availability"
      />
      <div
        class="product-box__price-wrapper"
        v-if="productBoxData.price || productBoxData.soldoutRelation"
      >
        <price-info
          v-if="!hidePrice"
          class="product-box__price"
          :value="productBoxData.price"
          :alcohol="productBoxData.alcohol"
          :show-star="!settings.price.showTax"
          :show-tax="settings.price.showTax"
        />
        <div
          class="product-box__buttons-wrapper"
          :class="{'product-box__buttons-wrapper--added-to-basket': productBoxData.inBasket ?? false}"
        >
          <product-qty-and-cart-btn
            :product-data="productBoxData"
            :product-link="productLink"
            :state="state"
            :add-successor-active="addSuccessorActive"
            @quantityChange="$emit('quantityChange', $event)"
            @addToBasket="$emit('addToBasket', $event)"
            @onRequestClicked="$emit('onRequestClicked', $event)"
          />

          <a
            v-if="productBoxData.showFoodSpecificationLink && !hideCounterButton"
            :href="foodSpecificationLink"
            class="product-box__link"
            v-html="$t('detail.product.characteristics')"
          />
          <div
            v-else
            class="product-box__link-empty"
          />
        </div>
      </div>
    </div>
  </div>
</template>

<script lang="ts">
  import {Options, Prop, Vue} from 'vue-property-decorator';
  import AvailabilityInfo from '../../../bps/components/availabilityInfo/availabilityInfo.vue'
  import ProductQtyAndCartBtn from '../../../bps/components/productQtyAndCartBtn/productQtyAndCartBtn.vue';
  import Ratings from '../../../bps/components/ratings/ratings.vue'
  import Lightbox from '../../../bps/components/lightbox/lightbox.vue'
  import Tags from '../../../bps/components/tags/tags.vue'
  import Flags from '../flags/flags.vue';
  import type ProductBoxData from '../../../bps/types/ProductBoxData';
  import {ProductBoxSettings} from './productBoxSettings';

  @Options({
    name: 'ProductBoxSegmentMain',
    components: {
      AvailabilityInfo,
      Ratings,
      Flags,
      Lightbox,
      Tags,
      ProductQtyAndCartBtn,
    },
  })
  export default class ProductBoxSegmentMain extends Vue {
    @Prop()
    productBoxData: ProductBoxData;
    @Prop()
    settings: ProductBoxSettings;
    @Prop()
    basketUrl: string;
    @Prop()
    foodSpecificationLink: string;
    @Prop()
    subscriptionLink: string;
    @Prop()
    hideCounterButton: boolean;
    @Prop()
    isBoxTypeVertical: boolean;
    @Prop()
    minimumRating: number;
    @Prop()
    showAvailability: boolean;
    @Prop({default: false})
    hidePrice: boolean;
    @Prop({default: false})
    addSuccessorActive: boolean;
    @Prop()
    state: string;
    @Prop()
    productLink: string;
  }
</script>
