<template>
  <div class="product-box__segment-image">
    <div
      class="product-box__rating-container"
      v-if="isBoxTypeHorizontal"
    >
      <div class="product-box__product-flags">
        <flags
          :class="{'product-box__product-flags--hidden': productBoxData.inBasket}"
          :flags="productBoxData.flags"
          :limit="3"
          :award-limit="1"
          :white-list="settings.flags.whitelist"
        />
      </div>
      <div>
        <div>
          <ratings
            class="product-box__ratings"
            v-if="productBoxData.ratings > minimumRating && !productBoxData.inBasket"
            :rating="productBoxData.ratings"
            :ratings-counter="productBoxData.ratingsCounter"
            :type="settings.rating.type"
          />
          <a
            class="product-box__status product-box__status--basket product-box__status--basket-up"
            v-if="productBoxData.inBasket"
            :href="basketUrl"
          >
            {{ $t('ads.ads_top_info.article_in_basket') }}
          </a>
          <a
            class="product-box__status product-box__status--basket product-box__status--basket-up"
            v-if="productBoxData.successorInBasket && !productBoxData.inBasket && addSuccessorActive"
            :href="basketUrl"
          >
            {{ $t('ads.ads_top_info.successor_in_basket') }}
          </a>
        </div>
        <div
          class="product-box__tags-wrapper"
          v-if="productBoxData.tags && !productBoxData.inBasket"
        >
          <flags
            :class="{'product-box__product-flags--hidden': productBoxData.inBasket}"
            :flags="productBoxData.flags"
            :limit="3"
            :award-limit="1"
            :white-list="settings.flags.whitelistTag"
          />
        </div>
      </div>
    </div>

    <component
      :is="productLink ? 'a' : 'div'"
      class="product-box__image-container"
      :href="productLink"
      :target="productLink && target ? target : null"
      aria-label="product image"
    >
      <div
        v-if="productBoxData.price && productBoxData.flags && isBoxTypeVertical"
        class="product-box__product-flags"
        :class="{
          'product-box__product-flags--hasSpecialFlags': hasSpecialFlags
        }"
      >
        <flags
          :class="{'product-box__product-flags--hidden': productBoxData.inBasket}"
          :flags="productBoxData.flags"
          :limit="4"
          :award-limit="2"
          :white-list="settings.flags.whitelist"
        />
      </div>
      <div class="product-box__image-wrapper">
        <image-element
          class="product-box__image"
          :src="imgSrc"
          :alt="productBoxData.images[0].alt"
        />
      </div>
    </component>
  </div>
</template>

<script lang="ts">
  import {Options, Prop, Vue} from 'vue-property-decorator';
  import Flags from '../flags/flags.vue'
  import ImageElement from '../../../bps/components/imageElement/imageElement.vue'
  import LazyLoad from '../../../bps/components/lazyLoad/lazyLoad.vue'
  import Ratings from '../../../bps/components/ratings/ratings.vue'
  import type ProductBoxData from '../../../bps/types/ProductBoxData';
  import {ProductBoxSettings} from './productBoxSettings';

  @Options({
    name: 'product-box-segment-image',
    components: {Flags, ImageElement, LazyLoad, Ratings},
  })
  export default class ProductBoxSegmentImage extends Vue {
    @Prop()
    productBoxData: ProductBoxData;
    @Prop()
    settings: ProductBoxSettings;
    @Prop()
    images: string[];
    @Prop()
    basketUrl: string;
    @Prop()
    productLink: string;
    @Prop()
    target: string;
    @Prop()
    isBoxTypeHorizontal: boolean;
    @Prop()
    isBoxTypeVertical: boolean;
    @Prop()
    minimumRating: number;
    @Prop({default: false})
    addSuccessorActive: boolean;

    get imgSrc(): string[] {
      return this.productBoxData.images.length > 2
        ? [this.productBoxData.images[0].src, this.productBoxData.images[2].src]
        : [this.productBoxData.images[0].src];
    }

    get hasSpecialFlags() {
      return !!this.productBoxData.flags.bottleSize || !!this.productBoxData.flags.takeAndPayBenefitText;
    }
  }
</script>
